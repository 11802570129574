import { makeStyles } from "@material-ui/styles"; 

export default makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.2)',
  },
  PreviewButton:{
     backgroundColor:'#3cd4a1',
     color:'white',
    '&:hover': {
      backgroundColor: '#3cd4a1'
    }
  },
  expansion: {
    backgroundColor: theme.palette.primary.light,
    color: "white "
  },
  textAreaBorder: {
    borderWidth: "0 0 1px",
    "&:focus": {
      outline: "none"
    }
  },
  feedBackQuestionBorder: {
    width: "100%",
    borderColor: "darkGrey",
    borderRadius: "3px",
    minHeight: "80px",
    "&:focus": {
      outline: "none"
    }
  },
  paper: {
    margin: "24px 0",
    backgroundColor: "#f3f3f3"
  },
  widgetTitle: {
    fontWeight: 800,
    fontSize: "25px",
    textAlign: "center"
  },
  widgetDescription: {
    fontWeight: 400,
    fontSize: "16px",
    textAlign: "center"
  },
  reasonIcon: {
    padding: "0px",
    fontSize: "25px"
  },
  modalPopUp: {
    position: 'relative',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 3),
    float: 'right',
    top: '65%',
    right: '12%'
  },
  nodeWrap: {
    minWidth: '380px',
    maxWidth: '380px',
    marginTop: '2px'
  },
  nodeTitle: {
    padding: '5px',
    fontSize: '15px',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 0
  },
  nodeTitleCustomize: {
    position: 'relative',
    paddingRight: '25px',
  },
  nodeDesc: {
    padding: '5px',
    color: '#000',
    fontSize: '12px'
  }, nodeChildWrap: {
    width: '120px',
  },
  nodeChildTitle: {
    padding: '5px 2px',
    fontSize: '13px',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 0
  },
  nodeDescIcon: {
    backgroundColor: "#eeeeee",
    textAlign: "center",
    color: '#000'
  },
  fontWeight500: {
    fontWeight: 500
  },
  scrollView: {
    maxHeight: '500px',
    width: '100%',
    overflowY: 'auto',
    borderTopWidth: '1px',
    borderTopColor: '#ccc',
    borderTopStyle: 'solid'
  },
  widgetCustomizeTitle: {
    color: 'rgb(110, 110, 110)',
    fontWeight: 400,
    fontSize: '1.142rem',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '2px'
  },
  relativePosistion: {
    position: 'relative'
  },
  panelRight: {
    position: 'absolute',
    maxWidth: '600px',
    zIndex: 1,
    right: '0px',
    top: '0px',
    backgroundColor: '#fff',
    height: '100%',
    padding: '1.5em',
    boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    overflowY: 'auto'
  },
  sortList: {
    borderBottom: '1px solid #fff',
    cursor: 'grabbing',
    position: 'relative',
    padding: 0
  }, sortListWrapper: {
    position: 'relative',
    paddingLeft: '1.5em',
    margin: '0px auto',
    maxWidth: '500px',
  },
  sortListText: {
    padding: '8px 16px'
  },
  editWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    right: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '24px',
    height: '22px',
    width: '22px',
    backgroundColor: '#e2e2e2',
    transition: 'all .35s ease',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
 editIcon: {
    fontSize: '13px',
    color: '#555'
  },
  tabHeader: {
    backgroundColor: '#f5f5f5',
  },
  tabPanel: {
    backgroundColor: '#ebebeb',
    marginTop: '10px',
    minHeight: '500px'
  },
  tabPanelSetting: {
    maxWidth: '500px',
    margin: '0px auto 20px'
  },
  paddIcon: {
    padding: '7px'
  }, flowOpactiy: {
    opacity: '0.3'
  },
  offerNode: {
    textAlign: 'center',
    padding: '0px 6px',
    width: '88px',
    height: 'auto',
  },
  media: {
    width: '100%'
  },
  linkLabelParent: {
    position: 'absolute',
    zIndex: '10'
  },
  linkLabel: {
    padding: '2px 5px',
    background: 'cornflowerblue',
    color: 'white',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  customPort: {
    width: '30px',
    height: '20px',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-3px'
  },
  offerTitle:{
      minWidth:'200px',
      color: '#4A4A4A'
  },
  tabPanelSettings: {
    maxWidth: '500px',
    margin: '0px auto 4px'
  },
  buttonSettings: {
    float:"right",
    margin:'4px 435px auto'

  },
}));
